<template>
  <template v-if="staffId > 0 && tabs.length > 0">
    <van-tabs v-model:active="active" :before-change="beforeChange">
      <van-tab v-for="(tab, index) in tabs" :title="tab.v" :key="index">
        <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="getData"
        >
          <div v-for="item in items" :key="item.orderId" style="margin:15px 0 0 0;">
            <van-cell :title="item.store.name" :value="item.statusText" icon="shop-o" />
            <van-card
              :num="item.productQuantity"
              :price="item.productPrice"
              :desc="item.productSkuText"
              :title="item.productName"
              :thumb="item.productImage"
            >
              <template #tags v-if="item.bookingDate">
                <van-tag plain type="danger">{{ item.bookingDate }}</van-tag>
              </template>
            </van-card>
            <div style="background:#fff;line-height:1.6;padding:7px;">
              <p>
                <small>
                  下单时间: {{ item.createTime }}
                </small>
              </p>
              <template v-if="item.bizType === 'BOOKING'">
                <p>
                  <small>
                    核销凭证: 总共 {{ item.credentialTotalQuantity }}张， 已用 {{ item.credentialUsedQuantity }} 张
                  </small>
                </p>
                <p>
                  <small>
                     有效期: 从 {{ item.credentialEffectStartTime }} 到 {{ item.credentialEffectEndTime }}
                  </small>
                </p>
              </template>
            </div>
          </div>
        </van-list>
      </van-tab>
    </van-tabs>
  </template>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'

export default {
  setup () {
    const post = inject('post')
    const useRoute = inject('useRoute')
    const Cookies = inject('Cookies')
    const state = reactive({
      page: 1,
      finished: false,
      loading: false,
      items: [],
      active: useRoute.query.tab ? parseInt(useRoute.query.tab) : 0,
      userId: Cookies.get('userId'),
      buyerId: Cookies.get('buyerId'),
      storeId: Cookies.get('storeId'),
      staffId: Cookies.get('staffId'),
      tabs: [
        {
          k: '0',
          v: '未结单',
          items: ['WAIT_USE', 'WAIT_DELIVER', 'DELIVERED']
        },
        {
          k: '1',
          v: '已结单',
          items: ['COMPLETED', 'REFUNDED', 'REFUSE_REFUNDED', 'EXPIRED']
        }
      ]
    })
    const init = () => {
      // state.tabs = listOrderStatus()
      // getData()
      // post('/staff.get', {
      //   storeId: state.storeId,
      //   userId: state.userId
      // }).then(res => {
      //   state.staffId = res.data.staffId
      // })
    }
    const getData = () => {
      post('/order.list', {
        pageNum: state.page,
        storeId: state.storeId,
        multiStatus: state.tabs[state.active].items,
        bizScope: 'GENERAL',
        technicianId: state.staffId
      }).then(res => {
        state.items = [...state.items, ...res.data.content]
        state.loading = false
        state.finished = res.data.last
        state.page++
      })
    }
    const reset = () => {
      state.items = []
      state.page = 1
      state.finished = false
      state.loading = false
    }
    const beforeChange = (index) => {
      state.active = index
      reset()
      return true
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      getData,
      beforeChange
    }
  }
}
</script>

<style scoped>
</style>
